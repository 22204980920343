import { useState, useEffect, useCallback } from "react";
import "./styles.css";
import {
  Grid,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  Dialog,
  Divider,
  Button,
  styled,
} from "@mui/material";
// import 'react-big-calendar/lib/sass/styles';
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import {
  createBooking,
  getBookingByWeek,
  getRequestBooking,
  reportBookingCapacity,
} from "../services/booking/BookingService";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/es";
import { Controller, useForm } from "react-hook-form";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { bookingApproval } from "../services/booking/BookingService";
import RejectBookingModal from "../components/utils/RejectBookingModal";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
const localizer = momentLocalizer(moment);
moment.locale("es");

const TextField = styled(TextValidator)(() => ({
  width: "100%",
}));

const FormHandlerBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const CalendaryPage = () => {
  const { t } = useTranslation();

  const parseStopsToArray = (
    stopsString: string | null | undefined
  ): string[] => {
    if (!stopsString) return [];
    return stopsString.split("|").filter((stop) => stop.trim() !== "");
  };

  const formatSingleHourTo12Hour = (time: string) => {
    const [hour, minutes] = time.split(":");

    let formattedHour = parseInt(hour, 10);
    let period = "AM";

    if (formattedHour === 0) {
      formattedHour = 12;
    } else if (formattedHour === 12) {
      period = "PM";
    } else if (formattedHour > 12) {
      formattedHour -= 12;
      period = "PM";
    }

    return `${formattedHour}:${minutes} ${period}`;
  };
  const { setLoading, setErrorMsg, setSuccessMsg, company, isAdmin, authInfo } =
    useApp();

  const [options, setOptions] = useState<any>([]);
  const [bookingData, setBookingData] = useState<any>([]);
  const [subOptions, setSubOptions] = useState<any>([]);
  const [eventos, setEventos] = useState([]);
  const formats = {
    // Formato del día
    // @ts-ignore
    dayFormat: (date, culture, localizer) => localizer.format(date, "ddd DD"),

    // Formato de la ranura de tiempo en el lado izquierdo del calendario
    // @ts-ignore
    timeGutterFormat: (date, culture, localizer) => {
      const formattedHour = localizer
        .format(date, "HH", culture)
        .padStart(2, "0"); // Añadir ceros a la izquierda si es necesario
      const hour12Format = parseInt(formattedHour, 10) >= 12 ? "PM" : "AM";
      let hour12 = parseInt(formattedHour, 10);
      if (hour12 > 12) {
        hour12 -= 12;
      } else if (hour12 === 0) {
        hour12 = 12;
      }
      return `${hour12.toString().padStart(2, "0")}:00 ${hour12Format}`; // Mostrar solo horas enteras y agregar ceros a la izquierda
    },

    // Formato del rango de tiempo de un evento
    // @ts-ignore
    eventTimeRangeFormat: (formattedRange, culture, localizer) => {
      const startTime = localizer.format(
        formattedRange.start,
        "hh:mm A",
        culture
      );
      const endTime = localizer.format(formattedRange.end, "hh:mm A", culture);
      return `${startTime} - ${endTime}`;
    },
  };
  const [completeData, setCompleteData] = useState<any>(null);
  const { setValue, watch, control } = useForm();
  const [slotReserve, setslotReserve] = useState<any>({});
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false);
  const [nextWeek, setNextWeek] = useState(moment().week());
  const [nextYear, setNextYear] = useState(moment().year());
  const [showEvent, setShowEvent] = useState({ open: false, event: "" });
  const [openFormReserve, setOpenFormReserve] = useState(false);
  const [rejEvent, setRejeEvent] = useState({});
  const [bookingTime, setBookingTime] = useState<any>(null);
  const [detailReserve, setDetailReserve] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);
  const [state, setState] = useState<any>({
    name: "",
    description: "",
    spaces: 0,
  });
  const [openModalReject, setOpenModalReject] = useState(false);
  const [idReject, setIdReject] = useState(0);

  const handleReject = (id: number) => {
    setIdReject(id);
    setOpenModalReject(true);
  };

  const handleCloseFormReserve = () => {
    setOpenFormReserve(false);
  };

  const watchSubOptions = watch("subOptions");
  const lang = {
    week: t("week"),
    work_week: t("work_week"),
    day: t("day"),
    month: t("month"),
    previous: t("previous"),
    next: t("next"),
    today: t("today"),
    agenda: t("agenda"),

    // @ts-ignore
    showMore: (total) => `+${total} más`,
  };

  const handleFetchBooking = async () => {
    setLoading && setLoading(true);
    setBookingData([]);
    setOptions([]);
    try {
      let bookingDataResponse = await getRequestBooking(
        0,
        "",
        isAdmin && company ? company : authInfo.companyId
      );
      if (bookingDataResponse) {
        setBookingData(bookingDataResponse.content);
      }
      const setOptionsData = bookingDataResponse.content?.map((item) => {
        return {
          value: item.id,
          label: item.name,
          subOptions: item.areaDetailList?.map((subItem) => {
            return {
              value: subItem.id,
              label: subItem.description,
            };
          }),
        };
      });

      setOptions(setOptionsData);

      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const searchBookingByWeek = async (id: number) => {
    setLoading && setLoading(true);
    setData([]);
    const currentDate = new Date().toISOString().slice(0, 10);

    try {
      const data = {
        idAreaDetail:
          id || parseInt(isAdmin && company ? company : authInfo.companyId),
        week: nextWeek,
        year: nextYear,
      };

      const searchData = bookingData.find((item: any) =>
        item.areaDetailList.find((subItem: any) => subItem.id === id)
      );

      setBookingTime(searchData?.capacity);
      const completeData = searchData?.areaDetailList.find(
        (subItem: any) => subItem.id === id
      );

      setCompleteData(completeData);

      let bookingDataWeek: any = await getBookingByWeek(
        isAdmin && company ? company : authInfo.companyId,
        data
      );

      setEventos(
        bookingDataWeek.days.map((evento: any) => ({
          id: evento.id,
          start: new Date(
            moment(evento.bookingDate).year(),
            moment(evento.bookingDate).month(),
            moment(evento.bookingDate).date(),
            evento.start.slice(0, 2),
            evento.start.slice(3, 5)
          ),
          end: new Date(
            moment(evento.bookingDate).year(),
            moment(evento.bookingDate).month(),
            moment(evento.bookingDate).date(),
            evento.end.slice(0, 2),
            evento.end.slice(3, 5)
          ),
          description:
            evento.description !== null
              ? evento.description
              : t("spaceReservation"),
          type: evento.type,
          allDay: evento.allDay,
          name: evento.name,
          phone: evento.phone,
          email: evento.email,
          status: evento.status,
          spaces: evento.spaces,
          observations: evento.observations,
          pickUpLocation: evento.pickUpLocation,
          dropOffLocation: evento.dropOffLocation,
          stops: parseStopsToArray(evento.stops || ""),
        }))
      );

      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchBooking();
    // searchBookingByWeek(1);
  }, [company]);

  useEffect(() => {
    searchBookingByWeek(watchSubOptions);
  }, [nextWeek, nextYear, company]);

  const onChangeOptions = (e: any) => {
    const subOptionsData = options.find(
      (item: any) => item.value === e.target.value
    )?.subOptions;
    setSubOptions(subOptionsData);
    setValue("subOptions", subOptionsData[0].value);
    searchBookingByWeek(subOptionsData[0].value);
  };

  useEffect(() => {
    if (watchSubOptions) {
      searchBookingByWeek(watchSubOptions);
    }
  }, [watchSubOptions, company]);

  const onNavigate = useCallback(
    // @ts-ignore
    (newDate) => {
      setNextWeek(moment(newDate).week());
      setNextYear(moment(newDate).year());
    },
    [setNextWeek, setNextYear]
  );

  // @ts-ignore
  const customDayPropGetter = (date) => {
    for (let index = 0; index < eventos.length; index++) {
      const element = eventos[index];
      // @ts-ignore
      if (element.allDay && element.start.getDay() === date.getDay()) {
        return {
          style: {
            backgroundColor: "#ebebeb",
          },
        };
      }
    }

    if (date < new Date()) {
      return {
        style: {
          backgroundColor: "#ebebeb",
        },
      };
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */

  // @ts-ignore
  const eventPropGetter = useCallback((event) => {
    switch (event.type) {
      case "CO":
        return {
          style: {
            backgroundColor: "#01AEEF",
          },
        };
      case "EX":
        return {
          style: {
            backgroundColor: " #0A3A7A",
          },
        };
      case "AD":
        return {
          style: {
            backgroundColor: "#FFC000",
          },
        };

      default:
        return;
    }
  }, []);

  let minday: { hour: any; minutes: any } = { hour: 0, minutes: 0 };
  let maxDay: { hours: any; minutes: any } = { hours: 0, minutes: 0 };
  let mindayWeekEnd: { hours: any; minutes: any } = { hours: 0, minutes: 0 };
  let maxDayWeekEnd: { hours: any; minutes: any } = { hours: 0, minutes: 0 };

  if (!!completeData) {
    minday = {
      hour: completeData.startTime.slice(0, 2),
      minutes: completeData.startTime.slice(3),
    };

    maxDay = {
      hours: completeData.endTime.slice(0, 2),
      minutes: completeData.endTime.slice(3),
    };

    mindayWeekEnd = {
      hours: completeData.startTimeWeekend.slice(0, 2),
      minutes: completeData.startTimeWeekend.slice(3),
    };

    maxDayWeekEnd = {
      hours: completeData.endTimeWeekend.slice(0, 2),
      minutes: completeData.endTimeWeekend.slice(3),
    };
  }

  const handleSelectSlot = useCallback(
    // @ts-ignore
    async ({ start, end, slots }) => {
      const selectedDate = moment(start).format("YYYY-MM-DD");

      let localData: any = null; // Variable local para almacenar temporalmente los datos

      if (completeData) {
        try {
          const reportData = await reportBookingCapacity(
            isAdmin && company ? company : authInfo.companyId,
            selectedDate,
            completeData?.idArea,
            bookingTime ? undefined : completeData?.id
          );

          if (reportData) {
            localData = reportData.content; // Almacenar los datos en la variable local
            setData(localData);
          }
        } catch (error) {}
      }

      // Convertir los tiempos seleccionados a cadenas de formato HH:mm
      const selectedStartTime = moment(start).format("HH:mm");
      const selectedEndTime = moment(end).format("HH:mm");

      let adjustedStartTime = selectedStartTime;
      let adjustedEndTime = selectedEndTime;

      // Verificar si el rango seleccionado coincide con algún rango devuelto por el endpoint
      let isInRange = false;
      for (const item of localData || []) {
        const availableTimeStart = item.hourStart;
        const availableTimeEnd = item.hourEnd;

        // Verificar si el rango seleccionado coincide exactamente con el rango actual en 'localData'
        if (
          selectedStartTime === availableTimeStart &&
          selectedEndTime === availableTimeEnd
        ) {
          isInRange = true;
          break;
        }

        // Verificar si el rango seleccionado está dentro de algún rango actual en 'localData'
        else if (
          selectedStartTime >= availableTimeStart &&
          selectedEndTime <= availableTimeEnd
        ) {
          isInRange = true;
          adjustedStartTime = availableTimeStart;
          adjustedEndTime = availableTimeEnd;
          break;
        }
      }

      if (!isInRange) {
        // Crear una lista plana de todos los rangos de horas disponibles
        const availableTimeRanges = [];
        for (const item of localData || []) {
          availableTimeRanges.push({
            start: item.hourStart,
            end: item.hourEnd,
          });
        }

        // Construir la cadena de todos los rangos de horas disponibles
        const validRanges = availableTimeRanges
          .map(
            (range) =>
              `${formatSingleHourTo12Hour(
                range.start
              )}-${formatSingleHourTo12Hour(range.end)}`
          )
          .join(", ");

        // Mostrar el mensaje de alerta con los rangos de horas disponibles
        Swal.fire({
          icon: "error",
          title: `${t("invalidRange")}`,
          text: `${t("validRanges")}: ${validRanges}`,
          confirmButtonColor: "#0A3A7A",
        });

        return;
      }

      // Permitir la selección de slots si el rango seleccionado coincide con algún rango devuelto por el endpoint
      const timeIntervals = [];
      let current = moment(adjustedStartTime, "HH:mm").startOf("hour");
      const endTime = moment(adjustedEndTime, "HH:mm").startOf("hour");

      while (current <= endTime) {
        timeIntervals.push(current.format("HH:mm"));
        current = current.add(30, "minutes");
      }

      setslotReserve({
        date: moment(start).format("DD/MM/YYYY"),
        start: adjustedStartTime,
        end: adjustedEndTime,
        slots: timeIntervals,
        day: moment(start).format("YYYY-MM-DD"),
      });

      setTimeout(() => {
        setShouldOpenEditorDialog(true);
      }, 300);
      setOpenFormReserve(true);
    },
    [maxDayWeekEnd, mindayWeekEnd]
  );
  // maxDayWeekEnd, mindayWeekEnd
  const handleFormSubmit = async () => {
    setLoading && setLoading(true);
    const res = await createBooking(isAdmin ? company : authInfo.companyId, {
      ...state,
    });

    if (res.result) {
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(res.message);
      searchBookingByWeek(watchSubOptions);
    } else {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(res.message);
    }
    handleCloseFormReserve();
    setState({
      name: "",
      description: "",
      spaces: 0,
    });
  };

  const handleChange = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      lastName: "",
      email: "admin@bitcode-enterprise.com",
      phone: "87073898",
      totalAmount: completeData.publicAmount * (slotReserve.slots.length - 1),
      payMethod: "ADMIN",
      bookingDetailList: [
        {
          areaAmount:
            completeData.publicAmount * (slotReserve.slots.length - 1),
          idAreaDetail: watchSubOptions,
          totalHours: slotReserve.slots.length - 1,
          startTime: slotReserve.start,
          endTime: slotReserve.end,
          bookingDay: slotReserve.day,
          fullDay: false,
          spaces: completeData.hasCapacity
            ? parseInt(event.target.value)
            : null,
          status: 3,
        },
      ],
    });
  };

  // @ts-ignore
  const titleEvents = ({ description = "", allDay, status, start }) => {
    const formattedTime = localizer.format(start, "hh:mm A");
    if (allDay) {
      return `${t("allDay2")} : ${description} `;
    }
    if (status === 2) {
      return `${t("pendingApprove")} : ${formattedTime} ${description} `;
    }
    if (status === 3) {
      return `${t("approve")} : ${formattedTime} ${description} `;
    }
    return description;
  };

  const hadleShowEventClose = () => {
    setRejeEvent(showEvent);
    setShowEvent({ open: false, event: "" });
  };

  const handleSelectEvent = useCallback(
    // @ts-ignore
    (event) => {
      setDetailReserve(event);
      setShowEvent({ open: true, event });
    },
    [setShowEvent]
  );

  const slotPropGetter = useCallback(
    // @ts-ignore
    (date) => {
      if (minday.hour !== mindayWeekEnd.hours) {
        return {
          ...(moment(date).hour() >
            new Date(
              0,
              0,
              0,
              maxDayWeekEnd.hours,
              maxDayWeekEnd.minutes,
              0
            ).getHours() -
              1 &&
            (date.getDay() === 6 || date.getDay() === 0) && {
              style: {
                backgroundColor: "#ebebeb",
              },
            }),
          ...(moment(date).hour() <
            new Date(
              0,
              0,
              0,
              mindayWeekEnd.hours,
              maxDayWeekEnd.minutes,
              0
            ).getHours() &&
            (date.getDay() === 6 || date.getDay() === 0) && {
              style: {
                backgroundColor: "#ebebeb",
              },
            }),
        };
      }
    },
    [minday, maxDayWeekEnd, mindayWeekEnd]
  );

  const bookingApprovalHandler = async (
    id: number,
    status: boolean,
    message: string = t("reserveSuccessfullyApproved"),
    user = authInfo.username
  ) => {
    const data = {
      id,
      status,
      message,
      user,
    };

    // Toca hacerlo

    const res = await bookingApproval(isAdmin ? company : authInfo.companyId, [
      data,
    ]);

    if (res.result) {
      setSuccessMsg &&
        setSuccessMsg(
          status ? t("reserveSuccessfullyApproved") : t("reservationRejected")
        );
      searchBookingByWeek(watchSubOptions);
      setShowEvent({ open: false, event: "" });
    } else {
      setErrorMsg && setErrorMsg(t("errorWhenApprovingTheReservation"));
      setShowEvent({ open: false, event: "" });
    }
    handleCloseFormReserve();
    setState({
      name: "",
      description: "",
      spaces: 0,
    });
    setOpenModalReject(false);
  };

  return (
    <>
      {/* <img src={Banner} width="100%" /> */}
      <FancyPaper pagetitle={`${t("bookings")} / ${t("calendar")}`}>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={12} sm={12} xs={12}>
            {/* <ResourceAccess isCode={true} pathOrCode={'USER:WRITE'}> */}
            <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // label='Area'
                  // value={selectArea}
                  onChange={onChangeOptions}
                  sx={{
                    width: "200px",
                    paddingLeft: "15px",
                    "& .MuiInputBase-root": {
                      marginLeft: "10px",
                    },
                  }}
                >
                  {options &&
                    options.map((item: any, idx: number) => {
                      return (
                        <MenuItem value={item.value} key={idx}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {subOptions.length > 0 && (
                <Controller
                  control={control}
                  name="subOptions"
                  // defaultValue={subOptions[0].value}
                  render={({ field }) => (
                    <FormControl>
                      <Select
                        {...field}
                        value={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // label='Area'
                        // value={selectArea}
                        onChange={field.onChange}
                        sx={{
                          marginLeft: "10px",
                          width: "200px",
                          paddingLeft: "15px",
                          "& .MuiInputBase-root": {
                            // borderRadius: '0.2rem 0 0 0.2rem',
                            marginLeft: "10px",
                          },
                        }}
                      >
                        {subOptions &&
                          subOptions.map((item: any) => {
                            return (
                              <MenuItem value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                />
              )}

              <Box display="flex" alignItems="center" gap={2}>
                <Box width="10px" height="10px" bgcolor="#0A3A7A" />
                <Typography>{t("externalReservations")}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Box width="10px" height="10px" bgcolor="#01AEEF" />
                <Typography>{t("associateReservations")}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Box width="10px" height="10px" bgcolor="#FFC000" />
                <Typography>{t("administratorReservations")}</Typography>
              </Box>
            </Box>

            {/* </ResourceAccess> */}
          </Grid>
        </Grid>
        {/* <TableContainer component={Paper}> */}
        {watchSubOptions && (
          // @ts-ignore
          <Calendar
            localizer={localizer}
            events={eventos}
            step={30}
            timeslots={2}
            messages={lang}
            selectable="ignoreEvents"
            min={new Date(2022, 0, 0, minday.hour, minday.minutes, 0)}
            max={new Date(2022, 0, 0, maxDay.hours, maxDay.minutes, 0)}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            formats={formats}
            views={["week"]}
            defaultView={"week"}
            onNavigate={onNavigate}
            dayPropGetter={customDayPropGetter}
            eventPropGetter={eventPropGetter}
            slotPropGetter={slotPropGetter}
            titleAccessor={titleEvents}
            // selectable={true}
          />
        )}

        {/* </TableContainer> */}
      </FancyPaper>
      {showEvent.open && (
        <Dialog onClose={hadleShowEventClose} open={showEvent.open}>
          <Box py={4} px={6}>
            <Typography align="center" variant="h4" mb={2}>
              {t("reservationDetails")}{" "}
            </Typography>
            <Typography variant="h6">
              <b>{t("idReservation")}:</b> {detailReserve.id}
            </Typography>
            <Typography variant="h6">
              <b>{t("reservation")}:</b> {detailReserve.description}
            </Typography>
            <Typography variant="h6">
              <b>{t("onBehalfOf")}:</b> {detailReserve.name}
            </Typography>
            <Typography variant="h6">
              <b>{t("state")}:</b>{" "}
              {detailReserve.status === 3 ? t("approved") : t("pendingApprove")}
            </Typography>
            {detailReserve.start && (
              <Typography variant="h6">
                <b>{t("start")}:</b>{" "}
                {moment(detailReserve.start).format("YYYY-MM-DD hh:mm A")}
              </Typography>
            )}
            {detailReserve.end &&
              !detailReserve.pickUpLocation &&
              !detailReserve.dropOffLocation && (
                <Typography variant="h6">
                  <b>{t("end")}:</b>{" "}
                  {moment(detailReserve.end).format("YYYY-MM-DD hh:mm A")}
                </Typography>
              )}
            {detailReserve.pickUpLocation && (
              <Typography variant="h6">
                <b>{t("pickUpLocation")}:</b> {detailReserve.pickUpLocation}
              </Typography>
            )}
            {detailReserve.dropOffLocation && (
              <Typography variant="h6">
                <b>{t("dropOffLocation")}:</b> {detailReserve.dropOffLocation}
              </Typography>
            )}
            {detailReserve.spaces && (
              <Typography sx={{ mb: 2 }} variant="h6">
                <b>{t("reservedSpaces")}:</b> {detailReserve.spaces}
              </Typography>
            )}
            {detailReserve.stops.length > 0 && (
              <>
                <Divider />
                <Typography align="center" variant="h4" mb={2}>
                  {t("stops")}{" "}
                </Typography>
                {detailReserve.stops.map((stop: string, index: number) => (
                  <Typography sx={{ mb: 2 }} variant="h6" key={stop}>
                    <b>
                      {t("stop")} {index + 1}:
                    </b>{" "}
                    {stop}
                  </Typography>
                ))}
              </>
            )}

            {detailReserve.observations && (
              <>
                <Divider />
                <Typography align="center" variant="h4" mb={2}>
                  {t("observations")}{" "}
                </Typography>
                <Typography sx={{ mb: 2 }} variant="h6">
                  <b>{t("generalObservations")}:</b>{" "}
                  {detailReserve.observations}
                </Typography>
              </>
            )}

            <Divider />
            <Typography align="center" variant="h4" my={2}>
              {t("contact")}
            </Typography>
            <Typography variant="h6">
              {" "}
              <b>{t("email")}: </b>
              {detailReserve.email}
            </Typography>
            <Typography variant="h6">
              {" "}
              <b>{t("phone")}: </b>
              {detailReserve.phone}
            </Typography>
            <Box display={"flex"} justifyContent={"center"} mt={4} gap={2}>
              {detailReserve.status === 3 ? (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // bookingApprovalHandler(detailReserve.id, false);
                      handleReject(detailReserve.id);
                    }}
                  >
                    {t("reject")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // setDialogOpen(true)
                      hadleShowEventClose();
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // bookingApprovalHandler(detailReserve.id, false);
                      handleReject(detailReserve.id);
                    }}
                  >
                    {t("reject")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleClose}
                    onClick={() => {
                      bookingApprovalHandler(detailReserve.id, true);
                    }}
                  >
                    {t("approve")}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Dialog>
      )}
      <Dialog onClose={handleCloseFormReserve} open={openFormReserve}>
        <Box p={3}>
          <Typography sx={{ mb: "10px" }} variant={"h5"}>
            {t("date")}: {slotReserve.date}{" "}
          </Typography>
          <Typography sx={{ mb: "20px" }} variant={"h5"}>
            {slotReserve.start &&
              slotReserve.end &&
              `${t("start")}: ${formatSingleHourTo12Hour(
                slotReserve.start
              )} ${t("end")}: ${formatSingleHourTo12Hour(slotReserve.end)}`}
          </Typography>

          <ValidatorForm onSubmit={() => handleFormSubmit()}>
            <Grid sx={{ mb: "16px" }} container spacing={{ xs: 2, sm: 4 }}>
              <Grid item sm={12} xs={12}>
                <TextField
                  label={`${t("name")}:`}
                  type="text"
                  name="name"
                  value={state?.name}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={[t("fieldRequired")]}
                  sx={{ py: 2, px: 2 }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  label={`${t("reservationReason")}:`}
                  type="text"
                  name="description"
                  value={state?.description}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={[t("fieldRequired")]}
                  sx={{ py: 2, px: 2 }}
                />
              </Grid>
              {completeData && completeData.hasCapacity && (
                <Grid item sm={12} xs={12}>
                  <TextField
                    label={`${t("spacesToReserve")}:`}
                    type="text"
                    name="spaces"
                    value={state?.spaces}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={[t("fieldRequired")]}
                    sx={{ py: 2, px: 2 }}
                  />
                </Grid>
              )}
            </Grid>

            <FormHandlerBox>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "20px" }}
                onClick={handleCloseFormReserve}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginRight: "20px" }}
              >
                {t("save")}
              </Button>
            </FormHandlerBox>
          </ValidatorForm>
        </Box>
      </Dialog>
      <RejectBookingModal
        openModalReject={openModalReject}
        handleCancelModalReject={() => {
          setOpenModalReject(false);
        }}
        onSubmit={async (data: any) => {
          bookingApprovalHandler(idReject, false, data.message);
        }}
        data={1}
      />
    </>
  );
};

export default CalendaryPage;
