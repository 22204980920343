import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Stack,
  Dialog,
  Tooltip,
  Typography,
  useTheme,
  Box,
  Card,
  CardContent,
  styled,
  Tabs,
  Tab,
  Link as LinkMaterial,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "4px",
  transition: "transform 0.3s ease-in-out",
  backgroundColor: "#f5f5f5",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: theme.shadows[10],
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "auto",
  padding: `${theme.spacing(1.5)}!important`,
  fontWeight: 600,
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const FeatureItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const PlanCardContent = ({ plan }: any) => {
  const { t } = useTranslation();

  return (
    <StyledCard elevation={plan.popular ? 8 : 2}>
      <CardContent
        sx={{
          p: 6,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {plan.popular && (
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              py: 0.5,
              px: 2,
              borderRadius: 1,
              alignSelf: "flex-start",
              mb: 2,
            }}
          >
            {t("mostPopular")}
          </Typography>
        )}
        <Typography variant="h5" component="h2" fontWeight="bold" gutterBottom>
          {plan.name}
        </Typography>
        {plan.price !== "" && (
          <Typography
            variant="h4"
            component="p"
            fontWeight="bold"
            sx={{ mb: 2 }}
          >
            ${plan.price}
            <Typography
              variant="subtitle1"
              component="span"
              color="text.secondary"
            >
              /{t("month")}
            </Typography>
          </Typography>
        )}

        <Typography color="text.secondary" sx={{ mb: 3 }}>
          {plan.description}
        </Typography>

        <Box sx={{ mb: 3 }}>
          {plan?.features?.map((feature: string, idx: number) => (
            <FeatureItem key={idx}>
              <CheckIcon sx={{ color: "#2196f3" }} />
              <Typography>{feature}</Typography>
            </FeatureItem>
          ))}
        </Box>
        {plan.price !== "" ? (
          <Link to={"/dashboard/checkout/" + plan.id}>
            <StyledButton
              variant={plan.popular ? "contained" : "outlined"}
              color="primary"
              fullWidth
              aria-label={`Choose ${plan.name}`}
            >
              {t("choosePlan")}
            </StyledButton>
          </Link>
        ) : (
          <LinkMaterial
            href={"https://spacemanager.io/?open=customPlan"}
            target="_blank"
          >
            <StyledButton
              variant={plan.popular ? "contained" : "outlined"}
              color="primary"
              fullWidth
              aria-label={`Choose ${plan.name}`}
            >
              {t("choosePlan")}
            </StyledButton>
          </LinkMaterial>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default PlanCardContent;
