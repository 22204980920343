import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import ColorPicker from "react-pick-color";
import { getRequest } from "../../services/country/CountryService";
import { ContentCurrency } from "../../services/currency/CurrencyInterface";
import CountrySelector from "./countrySelector/CountrySelector";
import {
  deleteDocuments,
  findDocuments,
} from "../../services/booking/BookingService";
import DeleteModal from "./DeleteModal";
import Swal from "sweetalert2";
import { ContentPlanList } from "../../services/planList/PlanListInterface";
import TabPlanCompany from "../../components/utils/TabPlanCompany";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdate {
  id?: number;
  description: string;
  url?: string;
  numberInquiries?: number;
  status?: {
    id?: number;
    name?: string;
  };
  continueBooking?: {
    id?: number;
    name?: string;
  };
  associated?: {
    id?: boolean;
    name?: string;
  };
  expirationDate?: number;
  hourEarlier?: number;
  phone: string;
  mail: string;
  color: string;
  language?: {
    id?: string;
    name?: string;
  };
  own_domain?: {
    id?: boolean;
    name?: string;
  };
  country: CountryOption;
  currencies: ContentCurrency[];
  planDetailDTO?: PlanDetail;
  listSocialNetwork: any[];
  logo?: File[];
  planListDTO?: PlantListDTO;
  admin?: AdminProps;
  adminFullName?: string;
}

interface PlanDetail {
  id: number;
  reservationAmount: number;
  price: number;
  initialDate: string;
  expirationDate: string;
}

interface CountryOption {
  id: number;
  name: string;
  areaCode: string;
  flag: string | null;
  userCreated: string;
  userModified: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  refreshData: any;
}

interface PlantListDTO {
  id: number;
  description: string;
  reservationAmount: number;
  price: number;
  durationPlan: number;
}

interface AdminProps {
  first_Name: string;
  last_name: string;
  country?: number;
  phone?: string;
  mail?: string;
}

const AddOrUpdateCompanyModal = ({
  data,
  onSubmit,
  cancelModal,
  modalType,
  refreshData,
}: DialogProps) => {
  const { t } = useTranslation();

  const {
    isAdmin,
    currencies,
    planList,
    company,
    authInfo,
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    modalData,
  } = useApp();

  //Validación de datos
  const validation = Yup.object().shape({
    description: Yup.string().required(t("fieldRequired")),
    url: Yup.string().required(t("fieldRequired")),
    phone: Yup.string().required(t("fieldRequired")),
    mail: Yup.string().required(t("fieldRequired")),
    numberInquiries: Yup.number()
      .required(t("fieldRequired"))
      .typeError("El campo debe ser numérico"),
    status: Yup.object().shape({
      id: Yup.number()
        .required(t("fieldRequired"))
        .typeError("El campo debe ser numérico"),
    }),
    continueBooking: Yup.mixed().required(t("fieldRequired")),
    associated: Yup.object().shape({
      id: Yup.boolean().required(t("fieldRequired")),
    }),
    own_domain: Yup.object().shape({
      id: Yup.boolean().required(t("fieldRequired")),
    }),
    expirationDate: Yup.number()
      .required(t("fieldRequired"))
      .typeError("El campo debe ser numérico")
      .min(1, "El campo debe ser mayor a 0")
      .max(30, "El campo debe ser menor a 31")
      .integer("El campo debe ser un número entero"),
    hourEarlier: Yup.number()
      .required(t("fieldRequired"))
      .typeError("El campo debe ser numérico")
      .min(0, "El campo debe ser mayor o igual a 0")
      .max(8, "El campo debe ser menor a 9")
      .integer("El campo debe ser un número entero"),
    adminFullName:
      modalType === "create"
        ? Yup.string().required(t("fieldRequired"))
        : Yup.mixed().notRequired(),
  });

  const getDefaultLanguage = (data: any) =>
    data
      ? {
          id: data.language,
          name: data.language === "es" ? t("spanish") : t("english"),
        }
      : {
          id: "es",
          name: t("spanish"),
        };

  const getDefaultStatus = (data: any) =>
    data
      ? {
          id: data.status,
          name: data.status === 0 ? t("active") : t("idle"),
        }
      : {
          id: 0,
          name: t("active"),
        };

  const getDefaultBooking = (data: any) =>
    data
      ? {
          id: data.continueBooking,
          name: data.continueBooking === 0 ? t("yes") : t("no"),
        }
      : {
          id: 0,
          name: t("yes"),
        };

  const getDefaultAdmin = (data: any) => ({
    first_Name: data?.admin?.first_Name ?? "",
    last_name: data?.admin?.last_name ?? "",
    country: data?.admin?.country ?? undefined,
    phone: data?.admin?.phone ?? "",
    mail: data?.admin?.mail ?? "",
  });

  const defaultValues = {
    id: data?.id || 0,
    description: data?.description || "",
    phone: data?.phone || "",
    mail: data?.mail || "",
    color: data?.color || "",
    language: getDefaultLanguage(data),
    url: data?.url || "",
    numberInquiries: data?.numberInquiries || 0,
    userCreated: data?.userCreated || "",
    status: getDefaultStatus(data),
    continueBooking: getDefaultBooking(data),
    associated: {
      id: data?.associated || false,
      name: data?.associated ? t("yes") : t("no"),
    },
    own_domain: {
      id: data?.own_domain || false,
      name: data?.own_domain ? "Si" : "No",
    },
    expirationDate: data?.expirationDate || 2,
    hourEarlier: data?.hourEarlier || 0,
    country: data?.country || null,
    currencies: data?.currencies || [],
    planDetailDTO: data?.planDetailDTO?.[0] || {},
    listSocialNetwork: data?.listSocialNetwork || [],
    logo: [] as File[],
    planListDTO: data?.planCompanyDTO?.planList
      ? planList.find(
          (plan: { id: any }) => plan.id === data.planCompanyDTO.planList
        ) || null
      : null,
    admin: getDefaultAdmin(data),
    adminFullName: data?.adminFullName || "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm<AddOrUpdate>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const [tabIndex, setTabIndex] = useState(0);
  const [countryData, setCountryData] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(
    null
  );
  const [url, setUrl] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [tripadvisorUrl, setTripadvisorUrl] = useState("");
  const [isLogoDeleteModalOpen, setLogoDeleteModalOpen] = useState(false);
  const [logoFileName, setLogoFileName] = useState("");
  const [isLogoSelected, setIsLogoSelected] = useState(false);
  const allowedExtensions = ["jpg", "jpeg", "png", "bmp"];
  const [enlargedImageUrl, setEnlargedImageUrl] = useState<string>("");
  const [logosByArea, setLogosByArea] = useState<any>({});
  const [isEnlargedImageVisible, setIsEnlargedImageVisible] =
    useState<boolean>(false);

  const removeImageUrlLogo = async (index: any) => {
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      const deletePhoto = await deleteDocuments(index, authInfo.username);
      if (!deletePhoto) {
        setErrorMsg && setErrorMsg(t("ErrorRemovingInstallation"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("RecordDeleted"));
      refreshData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  useEffect(() => {
    const fetchLogos = async () => {
      const logo: any = {};

      const images: any = await findDocuments(data?.id);

      //   logo = validImageUrls;

      setLogosByArea(images);
    };

    fetchLogos();
  }, [refreshData]);

  const handleFileChange2 = (index: number, event: any) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const fileExtension = files[0].name.split(".").pop()?.toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      Swal.fire({
        icon: "error",
        title: `${t("invalidFile")}`,
        text: `${t("FileExtNotAllowed")}`,
        confirmButtonColor: "#0A3A7A",
      });
      return;
    }

    if (files[0].size > 2000000) {
      Swal.fire({
        icon: "error",
        title: `${t("invalidFile")}`,
        text: `${t("FileSizeLess2MB")}`,
        confirmButtonColor: "#0A3A7A",
      });
      return;
    }

    const updatedFiles = [...files];
    updatedFiles[index] = files[0];
    setLogoFileName(files[0].name);

    //setFiles(updatedFiles);
    setValue(`logo.${index}`, files[0]);
    setIsLogoSelected(true);
  };

  const showEnlargedImage = (imageUrl: string) => {
    setEnlargedImageUrl(imageUrl);
    setIsEnlargedImageVisible(true);
  };

  const hideEnlargedImage = () => {
    setEnlargedImageUrl("");
    setIsEnlargedImageVisible(false);
  };

  const handleOpenLogoDeleteModal = () => {
    setLogoDeleteModalOpen(true);
  };

  const handleCloseLogoDeleteModal = () => {
    setLogoDeleteModalOpen(false);
  };

  useEffect(() => {
    const socialNetworks = data?.listSocialNetwork || [];
    socialNetworks.forEach((network: any) => {
      if (network.name === "facebook") {
        setFacebookUrl(network.url);
      } else if (network.name === "instagram") {
        setInstagramUrl(network.url);
      } else if (network.name === "youtube") {
        setYoutubeUrl(network.url);
      } else if (network.name === "tripadvisor") {
        setTripadvisorUrl(network.url);
      }
    });
  }, [data]);
  const handleUrlChange = (url: string) => {
    // Reemplazar espacios en blanco
    const newValue = url.replace(/\s/g, "");
    // Limitar a 50 caracteres
    const truncatedValue = newValue.slice(0, 50);
    setUrl(truncatedValue);
  };

  useEffect(() => {
    if (modalType === "update") {
      handleUrlChange(defaultValues.url);
    }
  }, [modalType]);

  const remainingCharacters = 50 - url.length;

  const handleFetchData = async () => {
    setCountryData([]);
    try {
      let data = await getRequest(0);
      if (data) {
        setCountryData(data);
        let selectedCountry = data.content.find(
          (country: any) => country.id == defaultValues.country
        );
        const selectedCountryObj: CountryOption = {
          id: selectedCountry?.id ? selectedCountry.id : 0,
          name: selectedCountry?.name ? selectedCountry.name : "",
          areaCode: selectedCountry?.areaCode ? selectedCountry.areaCode : "",
          flag: selectedCountry?.flag ? selectedCountry.flag : "",
          userCreated: selectedCountry?.userCreated
            ? selectedCountry.userCreated
            : "",
          userModified: selectedCountry?.userModified
            ? selectedCountry.userModified
            : "",
        };
        setSelectedCountry(selectedCountryObj);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    selectedCountry != null && setValue("country", selectedCountry);
  }, [selectedCountry]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          sx={{ "& .MuiTabs-flexContainer": { justifyContent: "center" } }}
        >
          <Tab label="Información de compañia" />
          {modalType === "update" && <Tab label="Información de plan" />}
        </Tabs>
      </Box>

      {tabIndex === 0 && (
        <>
          <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
            {modalType === "update" ? t("updateCompany") : t("addCompany")}
          </Title>
          <DialogContent sx={{ px: 4, pb: 0 }}>
            <form>
              <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
                <Grid item xs={12}>
                  <Controller
                    name={"description"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={t("name")}
                        type="text"
                        variant="standard"
                        value={value}
                        autoComplete="name"
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("description")}
                        error={
                          errors.description && Boolean(errors.description)
                        }
                        helperText={
                          errors.description && errors.description.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={"url"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={t("url")}
                        type="text"
                        variant="standard"
                        value={value}
                        autoComplete="url"
                        sx={{ "& input": { pl: "0!important" } }}
                        // {...register("url")}
                        inputProps={{
                          maxLength: 50,
                          onKeyPress: (event) => {
                            if (event.key === " ") {
                              event.preventDefault();
                            }
                          },
                        }}
                        error={errors.url && Boolean(errors.url)}
                        helperText={errors.url && errors.url.message}
                        onChange={(event) => {
                          handleUrlChange(event.target.value ?? "");
                          setValue("url", event.target.value);
                        }}
                      />
                    )}
                  />
                  {/* Contador de caracteres */}
                  <p>
                    {t("charactersUsed")}: {url.length} / 50 (
                    {t("remainingCharacters")}: {remainingCharacters})
                  </p>
                </Grid>
                {modalType === "create" && (
                  <Grid item xs={12}>
                    <Controller
                      name={"adminFullName"}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextField
                            fullWidth
                            size="small"
                            label={t("administrator")}
                            type="text"
                            variant="standard"
                            value={value}
                            autoComplete="administrator"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={
                              errors.adminFullName &&
                              Boolean(errors.adminFullName)
                            }
                            helperText={
                              errors.adminFullName &&
                              errors.adminFullName.message
                            }
                            onChange={(event) => {
                              const { value } = event.target;
                              setValue("adminFullName", value);
                              onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="own_domain"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={[
                          { id: false, name: t("no") },
                          { id: true, name: t("yes") },
                        ]}
                        getOptionLabel={(option: any) => option.name || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        //@ts-ignore
                        value={value}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label={t("ownDomainLabel")}
                              variant="standard"
                              sx={{ "& input": { pl: "0!important" } }}
                              error={!!errors.own_domain}
                              helperText={
                                errors.own_domain && errors.own_domain.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={"mail"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={t("email")}
                        type="email"
                        variant="standard"
                        value={value}
                        autoComplete="email"
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("mail")}
                        error={errors.mail && Boolean(errors.mail)}
                        helperText={errors.mail && errors.mail.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={"country"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CountrySelector
                        value={value ? value.id : null}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        onChange={onChange}
                        error={errors.country?.message}
                        options={countryData}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name={"phone"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={t("phone")}
                        type="text"
                        variant="standard"
                        value={value}
                        autoComplete="phone"
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("phone")}
                        error={errors.phone && Boolean(errors.phone)}
                        helperText={errors.phone && errors.phone.message}
                      />
                    )}
                  />
                </Grid>

                {isAdmin && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="status"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          options={[
                            { id: 0, name: t("active") },
                            { id: 1, name: t("idle") },
                          ]}
                          getOptionLabel={(option: any) => option.name || ""}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          //@ts-ignore
                          value={value}
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label={t("state")}
                                variant="standard"
                                sx={{ "& input": { pl: "0!important" } }}
                                error={!!errors.status}
                                helperText={
                                  errors.status && errors.status.message
                                }
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="continueBooking"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={[
                          { id: 0, name: t("yes") },
                          { id: 1, name: t("no") },
                        ]}
                        getOptionLabel={(option: any) => option.name || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        //@ts-ignore
                        value={value}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label={t("continueBooking")}
                              variant="standard"
                              sx={{ "& input": { pl: "0!important" } }}
                              error={!!errors.continueBooking}
                              helperText={
                                errors.continueBooking &&
                                errors.continueBooking.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="associated"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={[
                          { id: false, name: t("no") },
                          { id: true, name: t("yes") },
                        ]}
                        getOptionLabel={(option: any) => option.name || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        //@ts-ignore
                        value={value}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label={t("doYouAllowAssociates")}
                              variant="standard"
                              sx={{ "& input": { pl: "0!important" } }}
                              error={!!errors.status}
                              helperText={
                                errors.status && errors.status.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={"expirationDate"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={t("timeToExpirationInReserves")}
                        type="number"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        variant="standard"
                        value={value}
                        autoComplete="expirationDate"
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("expirationDate")}
                        error={
                          errors.expirationDate &&
                          Boolean(errors.expirationDate)
                        }
                        helperText={
                          errors.expirationDate && errors.expirationDate.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={"hourEarlier"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={t("hoursInAdvanceToMakeAReservation")}
                        type="number"
                        inputProps={{
                          maxLength: 1,
                          inputMode: "numeric",
                          pattern: "[0-8]*",
                        }}
                        variant="standard"
                        value={value}
                        autoComplete="hourEarlier"
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("hourEarlier")}
                        error={
                          errors.hourEarlier && Boolean(errors.hourEarlier)
                        }
                        helperText={
                          errors.hourEarlier && errors.hourEarlier.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={"language"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={[
                          { id: "en", name: t("english") },
                          { id: "es", name: t("spanish") },
                        ]}
                        getOptionLabel={(option: any) => option.name || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value
                        }
                        //@ts-ignore
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("language")}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.language}
                            helperText={
                              errors.language && errors.language.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={"currencies"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        multiple // Habilita la selección múltiple
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={currencies.filter(
                          (option: { id: number }) =>
                            !value.some(
                              (selectedOption) =>
                                selectedOption.id === option.id
                            )
                        )}
                        getOptionLabel={(option) => option.symbol}
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("currency")}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.currencies}
                            helperText={
                              errors.currencies && errors.currencies.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="planListDTO"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(_event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={planList}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value
                        }
                        //@ts-ignore
                        value={value}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label={t("reservationsPlan")}
                              variant="standard"
                              sx={{ "& input": { pl: "0!important" } }}
                              error={!!errors.planListDTO}
                              helperText={
                                errors.planListDTO && errors.planListDTO.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Facebook"
                    type="text"
                    variant="standard"
                    value={facebookUrl}
                    onChange={(e) => setFacebookUrl(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Instagram"
                    type="text"
                    variant="standard"
                    value={instagramUrl}
                    onChange={(e) => setInstagramUrl(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="YouTube"
                    type="text"
                    variant="standard"
                    value={youtubeUrl}
                    onChange={(e) => setYoutubeUrl(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Tripadvisor"
                    type="text"
                    variant="standard"
                    value={tripadvisorUrl}
                    onChange={(e) => setTripadvisorUrl(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="color"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => setShowColorPicker(!showColorPicker)}
                          style={{
                            background: data?.color ? data?.color : "primary",
                          }}
                        >
                          {t("customizeColor")}
                        </Button>
                        {showColorPicker && (
                          <ColorPicker
                            color={value}
                            onChange={(color: any) => onChange(color.hex)}
                          />
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ marginTop: "8px", marginBottom: "8px" }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ mb: 2, fontSize: "16px" }}
                    >
                      {t("logo")}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                        alignContent: "space-between",
                        width: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "8px",
                          width: "180px",
                        }}
                      >
                        <input
                          id={`logo-upload`}
                          type="file"
                          onChange={(event) => handleFileChange2(0, event)}
                          accept="image/png, image/jpeg, image/jpg"
                          style={{ display: "none" }}
                        />
                        <Button
                          variant="outlined"
                          component="label"
                          htmlFor={`logo-upload`}
                          sx={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {t("selectLogo")}
                        </Button>
                      </div>
                      {!isLogoSelected && logosByArea && logosByArea[0] && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "8px",
                            maxWidth: "180px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "15px",
                              mt: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {logosByArea[0].originalName}
                          </Typography>
                        </div>
                      )}
                      {!isLogoSelected &&
                        logosByArea &&
                        logosByArea[0] &&
                        modalType !== "create" && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "8px",
                              flex: 1,
                            }}
                          >
                            <div style={{ marginRight: "8px" }}>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  showEnlargedImage(
                                    `https://dispatcher.spacemanager.io/v1/documents/documents/view/${logosByArea[0].id}?originalName=${logosByArea[0].originalName}`
                                  );
                                }}
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {t("viewImage")}
                              </a>
                            </div>
                          </div>
                        )}
                      {!isLogoSelected && logosByArea && logosByArea[0] && (
                        <div style={{ marginRight: "-50px" }}>
                          <Dialog
                            open={isLogoDeleteModalOpen}
                            onClose={handleCloseLogoDeleteModal}
                            fullWidth
                          >
                            <DeleteModal
                              //@ts-ignore
                              textChildren={modalData?.modalObject?.username}
                              actionButton={
                                <>
                                  {" "}
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      mt: 2,
                                      mr: 2,
                                    }}
                                    onClick={() => {
                                      handleCloseLogoDeleteModal();
                                      removeImageUrlLogo(logosByArea[0].id);
                                    }}
                                  >
                                    {t("delete")}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                      mt: 2,
                                      mr: 2,
                                    }}
                                    onClick={() => {
                                      handleCloseLogoDeleteModal();
                                    }}
                                    autoFocus
                                  >
                                    {t("cancel")}
                                  </Button>{" "}
                                </>
                              }
                            />
                          </Dialog>
                          <IconButton
                            color="secondary"
                            onClick={handleOpenLogoDeleteModal}
                            style={{ marginLeft: "0px" }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      )}
                      {logoFileName && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "8px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "15px", mt: 1 }}
                          >
                            {logoFileName}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>

          {isEnlargedImageVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                zIndex: 9999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={hideEnlargedImage}
            >
              <img
                src={enlargedImageUrl}
                alt="Enlarged"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  margin: "auto",
                  maxWidth: "600px",
                  maxHeight: "400px",
                }}
              />
            </div>
          )}
          <Actions sx={{ pb: 4, px: 4 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={() => {
                // Actualizar los valores de las redes sociales en el objeto de datos
                setValue("listSocialNetwork", [
                  { name: "facebook", url: facebookUrl },
                  { name: "instagram", url: instagramUrl },
                  { name: "youtube", url: youtubeUrl },
                  { name: "tripadvisor", url: tripadvisorUrl },
                ]);

                // Obtener el valor actual del campo adminFullName
                const currentAdmin = getValues().adminFullName;

                const nameParts = (currentAdmin as string).split(" ");
                const firstName = nameParts[0] || "";
                const lastName = nameParts.slice(1).join(" ") || "";

                setValue("admin", {
                  first_Name: firstName,
                  last_name: lastName,
                  country: selectedCountry?.id || 0,
                  phone: getValues().phone || "",
                  mail: getValues().mail || "",
                });

                // Llamar a onSubmit después de actualizar los valores
                handleSubmit(onSubmit)();
              }}
            >
              {t("save")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={cancelModal}
              autoFocus
            >
              {t("cancel")}
            </Button>{" "}
          </Actions>
        </>
      )}
      {tabIndex === 1 && modalType === "update" && (
        <TabPlanCompany
          data={data.planDetailDTO}
          modalType={modalType}
          refreshData={handleFetchData}
        />
      )}
    </>
  );
};

export default AddOrUpdateCompanyModal;
