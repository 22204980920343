/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FancyPaper from "../components/FancyPaper";
import ResourceAccess from "../components/security/ResourceAccess";
import { PenIcon } from "../components/svgicons/SvgIcons";
import AcceptOrRejectBookingModal from "../components/utils/AcceptOrRejectBookingModal";
import DeleteModal from "../components/utils/DeleteModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyTooltip from "../components/utils/FancyTooltip";
import { useApp } from "../hooks/useApp";
import {
  bookingApproval,
  bookingFindByStatus,
  getRequestBooking,
} from "../services/booking/BookingService";
import {
  InputBookingApproval,
  StatusSearchBooking,
} from "../types/ServiceTypes";

const BookingList = () => {
  const { t } = useTranslation();

  const {
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    authInfo,
    company,
    isAdmin,
    errorMsg,
  } = useApp();
  const searchBooking: {
    id: StatusSearchBooking;
    label: string;
  }[] = [
    {
      id: StatusSearchBooking.PEN,
      label: t("reservationsPendingApproval"),
    },
    {
      id: StatusSearchBooking.EXP,
      label: t("expiredReservations"),
    },
    {
      id: StatusSearchBooking.APR,
      label: t("approvedReservations"),
    },
    {
      id: StatusSearchBooking.REJ,
      label: t("rejectedReservations"),
    },
  ];
  const theme = useTheme();
  const [bookingData, setBookingData] = useState<any>([]);
  const { handleSubmit } = useForm<InputBookingApproval>();
  const [status, setStatus] = useState<StatusSearchBooking>(
    searchBooking[0].id
  );
  const [preFilter, setPreFilter] = useState<string | undefined>("");
  const [page, setPage] = useState(0);
  const [instalationOptions, setInstalationOptions] = useState<any>([]);
  const [subInstalationOptions, setSubInstalationOptions] = useState<any>([]);
  const [selectInstalation, setSelectInstalation] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [selectSubInstalation, setSelectSubInstalation] = useState<{
    id: number;
    name: string;
  } | null>(null);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      // Verificar si el usuario es administrador y no ha seleccionado una compañía
      if (isAdmin && !company) {
        return; // Salir de la función si no hay compañía seleccionada
      }

      await handleFetchData(0, preFilter);
    };

    dataInit();

    // eslint-disable-next-line
  }, [status, company, selectSubInstalation]);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (currentPage: number, filter?: string) => {
    setLoading && setLoading(true);
    setBookingData([]);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let data = await bookingFindByStatus(
        isAdmin && company ? company : authInfo.companyId,
        {
          status: status,
          area: selectSubInstalation ? Number(selectSubInstalation.id) : null,
          filter: filter,
        },
        currentPage
      );

      if (data) {
        setBookingData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData, company]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      object =
        bookingData &&
        bookingData.content &&
        bookingData.content.find((item: any) => item.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: InputBookingApproval) => {
    try {
      setLoading && setLoading(true);

      try {
        const bookingApprovalData = await bookingApproval(
          isAdmin ? company : authInfo.companyId,
          [
            {
              id: data.id,
              status: data.status,
              user: authInfo.username,
              message: data.status ? t("bookingApproved") : t("bookingReject"),
            },
          ]
        );

        setSuccessMsg &&
          setSuccessMsg(
            bookingApprovalData && data.status
              ? t("bookingApproved")
              : t("bookingReject")
          );
      } finally {
        // Reset loading state
        setLoading && setLoading(false);

        // Close modal
        handleCancelModal();
        handleFetchData(0, "");
      }
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const onChangeOptions = (e: any) => {
    setStatus(e.target.value);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  const handleFetchBooking = async () => {
    setLoading && setLoading(true);
    //setBookingData([]);
    //setOptions([]);
    setSelectInstalation(null);
    setSelectSubInstalation(null);
    try {
      let bookingDataResponse = await getRequestBooking(
        0,
        "",
        isAdmin ? company : authInfo.companyId
      );
      // if (bookingDataResponse) {
      //   setBookingData(bookingDataResponse.content);
      // }

      if (bookingDataResponse) {
        setInstalationOptions(bookingDataResponse.content);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchBooking();
  }, [company]);

  const handleInstalationChange = (event: any, newValue: any) => {
    setSelectInstalation(newValue);
    if (newValue) {
      // Filtrar las subinstalaciones correspondientes a la instalación seleccionada
      if (newValue.areaDetailList) {
        setSubInstalationOptions(newValue.areaDetailList);
        // Seleccionar automáticamente la primera subinstalación si está disponible
        if (newValue.areaDetailList.length > 0) {
          setSelectSubInstalation(newValue.areaDetailList[0]);
        }
      } else {
        setSubInstalationOptions([]);
      }
    } else {
      // Limpiar las subinstalaciones cuando no hay ninguna instalación seleccionada
      setSubInstalationOptions([]);
      setSelectSubInstalation(null);
    }
  };

  return (
    <>
      <FancyPaper pagetitle={`${t("bookings")} / ${t("bookingsList")}`}>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={12} sm={6} xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Box
                display="flex"
                alignContent="center"
                alignItems="center"
                gap={4}
              >
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    onChange={onChangeOptions}
                    sx={{
                      width: "300px",
                      paddingLeft: "15px",
                      "& .MuiInputBase-root": {
                        marginLeft: "10px",
                      },
                    }}
                  >
                    {searchBooking.map((item: any, idx: number) => {
                      return (
                        <MenuItem value={item.id} key={idx}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <Autocomplete
                  value={selectInstalation}
                  onChange={handleInstalationChange}
                  options={instalationOptions}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("category")}
                      variant="standard"
                      fullWidth
                      sx={{
                        marginTop: -5,
                        width: 200,
                        "& fieldset": {
                          borderColor: "blue",
                        },
                        "& svg": {
                          color: "blue",
                        },
                      }}
                    />
                  )}
                />
                <Autocomplete
                  value={selectSubInstalation}
                  onChange={(event: any, newValue: any) => {
                    setSelectSubInstalation(newValue);
                  }}
                  options={subInstalationOptions}
                  getOptionLabel={(option: any) => option.description}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("subCategory")}
                      variant="standard"
                      fullWidth
                      sx={{
                        marginTop: -5,
                        width: 200,
                        "& fieldset": {
                          borderColor: "blue",
                        },
                        "& svg": {
                          color: "blue",
                        },
                      }}
                    />
                  )}
                />
              </Box>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <TextField
                  placeholder={t("searchFilter")}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "0.2rem 0 0 0.2rem",
                    },
                    "& fieldset": {
                      borderRightWidth: "0",
                    },
                  }}
                  value={preFilter}
                  onChange={(e: any) => {
                    setPreFilter(e.target.value);
                    if (e.target.value === "") {
                      handleFetchData(0, "");
                    }
                  }}
                  onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleApplyFilter}
                  sx={{
                    borderRadius: "0 0.2rem 0.2rem 0!important",
                    padding: "1.5px 16px!important",
                  }}
                >
                  {t("search")}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("reservationN°")}</TableCell>
                <TableCell align="center">{t("name")}</TableCell>
                <TableCell align="center">{t("lastName")}</TableCell>
                <TableCell align="center">{t("category")}</TableCell>
                <TableCell align="center">{t("totalAmount")}</TableCell>
                <TableCell align="center">{t("email")}</TableCell>
                <TableCell align="center">{t("phone")}</TableCell>
                <TableCell align="center">{t("paymentMethod")}</TableCell>
                <TableCell align="center">
                  {status !== 4 ? t("approveBy") : t("rejectedBy")}
                </TableCell>

                <TableCell align="center">{t("applicationDate")}</TableCell>
                {(status === StatusSearchBooking.EXP ||
                  status === StatusSearchBooking.PEN) && (
                  <TableCell align="center">
                    {status === StatusSearchBooking.EXP
                      ? t("expirationDate")
                      : t("expirationDates")}
                  </TableCell>
                )}

                <TableCell align="center">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookingData &&
                bookingData.content &&
                bookingData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.lastName}</TableCell>

                    <TableCell align="center">{row.areaName}</TableCell>
                    <TableCell align="center">{row.areaAmount}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.payMethod}</TableCell>
                    <TableCell align="center">
                      {row.approverUser ?? t("notAvalible")}
                    </TableCell>
                    <TableCell align="center">
                      {row.creationDate.substring(0, 10)}
                    </TableCell>
                    {(status === StatusSearchBooking.EXP ||
                      status === StatusSearchBooking.PEN) && (
                      <TableCell align="center">
                        {row.expirationDate
                          ? row.expirationDate.substring(0, 10)
                          : t("notAvalible")}{" "}
                      </TableCell>
                    )}

                    <TableCell align="center">
                      <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                        <FancyTooltip title={t("edit")} placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            bookingData?.content?.length > 0 ? bookingData?.content?.length : 0
          }
          rowsPerPage={bookingData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={bookingData.totalElements}
          totalPages={bookingData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AcceptOrRejectBookingModal
            data={modalData.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default BookingList;
