import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import FancyPaper from "../components/FancyPaper";
import { PenIcon, PlusIcon } from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";

import { useForm } from "react-hook-form";
import AddOrUpdateCompanyModal from "../components/utils/AddOrUpdateCompanyModal";
import DeleteModal from "../components/utils/DeleteModal";
import { useApp } from "../hooks/useApp";

import {
  createRequest,
  deleteRequest,
  getRequestCompany,
  getRequestOneCompany,
  updateRequest,
} from "../services/company/CompanyService";

import { useTranslation } from "react-i18next";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { ContentCurrency } from "../services/currency/CurrencyInterface";
import {
  deleteDocuments,
  findDocuments,
  uploadDocuments,
} from "../services/booking/BookingService";

const SettingCompanies = () => {
  const { t } = useTranslation();

  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    isAdmin,
    handleDataCompanies,
    company,
  } = useApp();

  const theme = useTheme();
  const [companyData, setCompanyData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);

  const initForm = {
    id: 0,
    company: "",
    subCompany: "",
    numberInquiries: "",
    addSubCompanies: [],
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();
  }, []);

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const handleFetchData = async (currentPage: number, filter?: string) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);
      if (isAdmin || authInfo.userRole.id === 9) {
        let data: any = await getRequestCompany(currentPage, filter);

        if (data) {
          setCompanyData(data);
        }
        setLoading && setLoading(false);
      } else {
        let data: any = await getRequestOneCompany(
          currentPage,
          filter,
          authInfo.companyId
        );

        if (data) {
          setCompanyData(data);
        }
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any) => {
    // Filtrar las redes sociales con URL no vacía
    const filteredSocialNetworks = data.listSocialNetwork.filter(
      (socialNetwork: any) => socialNetwork.url !== ""
    );

    // Convertir las redes sociales en una lista de objetos
    const listSocialNetwork = filteredSocialNetworks.map(
      (socialNetwork: any) => ({
        company: data.id,
        name: socialNetwork.name,
        url: socialNetwork.url,
      })
    );

    // Crear una copia de los datos originales y agregar la lista de redes sociales
    const modifiedData = {
      ...data,
      listSocialNetwork: listSocialNetwork,
      currencies: Array.isArray(data.currencies)
        ? data.currencies
        : [data.currencies],
    };

    // Convertir 'currencies' en una lista de objetos
    modifiedData.currencies = modifiedData.currencies.map(
      (currency: ContentCurrency) => ({
        id: currency.id,
        name: currency.name,
        symbol: currency.symbol,
        userCreated: currency.userCreated,
        userModified: currency.userModified,
      })
    );

    switch (modalData?.modalType) {
      case "create":
        await handleAdd(modifiedData);
        break;
      case "update":
        await handleUpdate(modifiedData);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        status: data.status.id,
        continueBooking: data.continueBooking.id,
        userCreated: authInfo?.username,
        associated: data.associated.id,
        language: data.language.id,
        own_domain: data.own_domain.id,
        country: data.country.id,
        currencies: data.currencies,
        planList: data.planListDTO && data.planListDTO.id,
        planCompanyId: data.planCompanyId,
      });
      const idCompany = createData.id;
      if (!createData) {
        setErrorMsg && setErrorMsg(t("errorCreatingCompany"));
        setLoading && setLoading(false);
        return;
      }
      const logo = data?.logo[0];
      if (!!data.logo.length) {
        const formData = new FormData();
        formData.append("idDocumentCategory", "2");
        formData.append("idCompany", idCompany);
        formData.append("files", logo);
        formData.append("user", authInfo.username);
        await uploadDocuments(formData);
      }
      setSuccessMsg && setSuccessMsg(t("successfullyCreatedCompany"));
      handleDataCompanies();
      setLoading && setLoading(false);
      setSuccessMsg && (await handleFetchData(0, ""));
    } catch (error: any) {
      setErrorMsg && setErrorMsg(t("errorCreatingCompany"));
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);

    try {
      let updateData = await updateRequest({
        ...data,
        status: data.status.id,
        continueBooking: data.continueBooking.id,
        associated: data.associated.id,
        language: data.language.id,
        userModified: authInfo?.username,
        own_domain: data.own_domain.id,
        country: data.country.id,
        planCompanyId: data.planCompanyId,
        planListDTO: data.planListDTO && data.planListDTO,
        plantList: data.planListDTO && data.planListDTO.id,
      });

      if (!updateData) {
        setErrorMsg && setErrorMsg(t("errorUpdatingCompany"));
        setLoading && setLoading(false);
        return;
      }

      const logoUpdate = data.logo[0];

      if (!!logoUpdate) {
        const images: any = await findDocuments(data.id);

        if (images.length > 0) {
          for (const logo of images) {
            const deletePhoto = await deleteDocuments(
              logo.id,
              authInfo.username
            );
          }
        }

        const formData = new FormData();
        formData.append("idDocumentCategory", "2");
        formData.append("idCompany", data.id);
        formData.append("files", logoUpdate);
        formData.append("user", authInfo.username);
        await uploadDocuments(formData);
      }

      handleDataCompanies();
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("companyUpdatedCorrectly"));
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          //setErrorMsg(properties.com_parval_label_user_delete_error);
          setLoading &&
          setLoading(false);
        return;
      }
      handleDataCompanies();
      setLoading && setLoading(false);
      setSuccessMsg &&
        //setSuccessMsg(properties.com_parval_label_request_delete);

        //reset page and call fetch data
        (await handleFetchData(0, ""));
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      // await handleFetchByID(id);
      object = companyData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = companyData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  return (
    <>
      <FancyPaper pagetitle={`${t("setting")} / ${t("company")}`}>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
              {isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                  data-name="create"
                >
                  {t("addCompany")}
                  <PlusIcon sx={{ ml: 1 }} />
                </Button>
              )}
            </ResourceAccess>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <TextField
                  placeholder={t("searchFilter")}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "0.2rem 0 0 0.2rem",
                    },
                    "& fieldset": {
                      borderRightWidth: "0",
                    },
                  }}
                  value={preFilter}
                  onChange={(e: any) => {
                    setPreFilter(e.target.value);
                    if (e.target.value === "") {
                      handleFetchData(0, "");
                    }
                  }}
                  onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleApplyFilter}
                  sx={{
                    borderRadius: "0 0.2rem 0.2rem 0!important",
                    padding: "1.5px 16px!important",
                  }}
                >
                  Buscar
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("id")}</TableCell>
                <TableCell align="center">{t("name")}</TableCell>
                <TableCell align="center">{t("url")}</TableCell>
                <TableCell align="center">{t("state")}</TableCell>
                <TableCell align="center">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData &&
                companyData.content &&
                companyData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.url}
                    </TableCell>
                    <TableCell align="center">
                      {row.deleted ? t("idle") : t("active")}
                    </TableCell>
                    <TableCell align="center">
                      <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                        <FancyTooltip title={t("edit")} placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        {/* <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip> */}
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            companyData?.content?.length > 0 ? companyData?.content?.length : 0
          }
          rowsPerPage={companyData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={companyData.totalElements}
          totalPages={companyData.totalPages}
        />
      </FancyPaper>

      {(modalData?.modalType === "update" ||
        modalData?.modalType === "create") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateCompanyModal
            data={modalData.modalObject}
            onSubmit={onSubmit}
            refreshData={handleFetchData}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}

      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.nombre}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("save")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingCompanies;
