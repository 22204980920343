import { lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import DashboardHome from "../layouts/DashboardHome";

import ForgotPassword from "../pages/login/ForgotPassword";
import Login from "../pages/login/Login";
import RememberPassword from "../pages/login/RememberPassword";
import ActiveRegister from "../pages/session/ActiveRegister";

import { AuthLayout } from "../components/security/AuthLayout";
import AddOrUpdPostCard from "../components/utils/AddOrUpdPostCard";
import AssociatedManagment from "../pages/AssociatedManagment";
import BookingList from "../pages/BookingList";
import CalendaryPage from "../pages/CalendaryPage";
import ConsumptionHistory from "../pages/ConsumptionHistory";
import SettingAuthority from "../pages/SettingAuthority";
import SettingAuthorityByRoleManagement from "../pages/SettingAuthorityByRoleManagement";
import SettingCompanies from "../pages/SettingCompanies";
import SettingCountries from "../pages/SettingCountries";
import SettingRoles from "../pages/SettingRoles";
import SettingUser from "../pages/SettingUser";
import ValidateClient from "../pages/ValidateClient";
import News from "../pages/maintenance/News";
import OpenApi from "../pages/maintenance/OpenApi";
import {
  default as Booking,
  default as SettingBooking,
} from "../pages/maintenance/booking/SettingBooking";
import { GuestsProvider } from "../pages/maintenance/guests/GuestsContext";
import GuestsList from "../pages/maintenance/guests/GuestsList";
import { MessagesProvider } from "../pages/maintenance/messages/MessagesContext";
import MessagesList from "../pages/maintenance/messages/MessagesList";
import AdminDashboard from "../pages/AdminDashboard";
import CustomerDashboard from "../pages/CustomerDashboard";
import DashboardPost from "../pages/post/DashboardPost";
import SettingApartments from "../pages/SettingApartments";
import SettingPayMethod from "../pages/SettingPayMethod";
import SettingNotifications from "../pages/SettingNotifications";
import SettingApartmentsPendings from "../pages/SettingApartmentsPendings";
import SettingFinancialStatements from "../pages/SettingFinancialStatements";
import SettingApartmentsPaid from "../pages/SettingApartmentsPaid";
import PostCategories from "../pages/maintenance/PostCategories";
import { getAuthData } from "../utils/LocalStorageManager";
import ReportBookingPayment from "../pages/reports/ReportBookingPayment";
import ReportBookingCapacity from "../pages/reports/ReportBookingCapacity";
import UpgradeMembershipBooking from "../pages/tilopayPayment/UpgradeMembershipBookingPlans";
import PaymentFormTilopay from "../pages/tilopayPayment/PaymentFormTilopay";
import ApprovePayment from "../pages/tilopayPayment/ApprovePayment";
const Session = lazy(() => import("../pages/session/Session"));
const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      {/* <Route path="/session" element={<Session />} /> */}
      <Route path="/" element={<Login />} />
      <Route path="/rememberpassword" element={<RememberPassword />} />
      <Route
        path="/forgot-password/:code/:email"
        element={<ForgotPassword />}
      />
      <Route
        path="/active-register/:code/:email"
        element={<ActiveRegister />}
      />
      <Route path="/dashboard" element={<DashboardHome />}>
        <Route path="home" element={<AdminDashboard />} />
        <Route path="customerHome" element={<CustomerDashboard />} />
        <Route path="post" element={<DashboardPost />} />
        <Route path="news" element={<News />} />
        <Route path="news/create" element={<AddOrUpdPostCard />} />
        <Route path="news/:postID/:postTitle" element={<AddOrUpdPostCard />} />
        <Route path="categories" element={<PostCategories />} />
        <Route path="validateclient" element={<ValidateClient />} />
        <Route path="consumptionhistory" element={<ConsumptionHistory />} />
        <Route path="openapi" element={<OpenApi />} />
        <Route path="booking" element={<Booking />} />
        <Route path="settinguser" element={<SettingUser />} />
        <Route path="settingroles" element={<SettingRoles />} />
        <Route path="settingcountries" element={<SettingCountries />} />
        <Route path="settingcompanies" element={<SettingCompanies />} />
        <Route path="settingauthority" element={<SettingAuthority />} />
        <Route path="settingbooking" element={<SettingBooking />} />
        <Route path="associates" element={<AssociatedManagment />} />
        <Route path="apartments" element={<SettingApartments />} />
        <Route path="settingPayMethod" element={<SettingPayMethod />} />
        <Route path="settingNotifications" element={<SettingNotifications />} />
        <Route
          path="settingFinancialStatements"
          element={<SettingFinancialStatements />}
        />
        <Route
          path="apartmentspendings"
          element={<SettingApartmentsPendings />}
        />
        <Route path="apartmentsPaid" element={<SettingApartmentsPaid />} />
        <Route path="reportbookingpayment" element={<ReportBookingPayment />} />
        <Route
          path="reportbookingcapacity"
          element={<ReportBookingCapacity />}
        />

        <Route path="booking-system" element={<CalendaryPage />} />
        <Route path="booking-list" element={<BookingList />} />
        <Route
          path="guests"
          element={
            <GuestsProvider>
              <GuestsList />
            </GuestsProvider>
          }
        />
        <Route
          path="message"
          element={
            <MessagesProvider>
              {" "}
              <MessagesList />{" "}
            </MessagesProvider>
          }
        />

        <Route
          path="authoritiesbyrole/:roleId"
          element={<SettingAuthorityByRoleManagement />}
        />
        <Route
          path="upgradeMembershipBooking"
          element={<UpgradeMembershipBooking />}
        />
        <Route path="checkout/:planID" element={<PaymentFormTilopay />} />
        <Route path="approvePayment" element={<ApprovePayment />} />

        {/*mywebsite.com?code=1&description=Transaction%20is%20approved.&auth=123456&order=TPYS-881WROIBQA1405468&tpt=4300&crd=&tilopay-transaction=4300&OrderHash=b02927cb2304774afd1fe97df1f6f066218ea34427289c1265f4e96c86653144&returnData=Tilopay-dataReturn123456789&form_update=ok*/}
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      {/* <Route path="*" element={<Navigate to="/notfound" replace />} /> */}
    </Route>
  )
);
