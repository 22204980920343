import { t } from "i18next";
import UrlBase from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";

export const getRequestCompany = async (
  page: any,
  filter: any,
  size?: number
) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(
      URL.REQUEST_COMPANY(
        page,
        size ? size : Number(UrlBase.registration_x_page)
      ),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestOneCompany = async (
  page: any,
  filter: any,
  company?: number,
  size?: number
) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(
      URL.REQUEST_COMPANY_API(
        page,
        size ? size : Number(UrlBase.registration_x_page),
        company
      ),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_COMPANY_BYID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData: any = {};
  await privateFetch
    .post(URL.REQUEST_COMPANY_CREATE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  try {
    const response = await privateFetch.put(
      URL.REQUEST_COMPANY_UPDATE(data.id),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );
    responseData = response.data;
  } catch (error) {
    throw new Error(t("errorUpdatingCompany"));
  }
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_COMPANY_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error);
    });
  return responseData;
};

export const updateCompanyWithPlanID = async (
  id: string,
  planCompanyId: string,
  planDetailId: string,
  planCardId: string,
  data: any
) => {
  let responseData = {};
  try {
    const response = await privateFetch.put(
      URL.REQUEST_COMPANY_UPDATE_PLAN_LIST(
        id,
        planCompanyId,
        planDetailId,
        planCardId
      ),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );
    responseData = response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_role_update_error);
  }
  return responseData;
};
