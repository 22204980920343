import { Box, Paper, Typography, Divider, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useApp } from "../hooks/useApp";
import { formatDate } from "../utils/UtilsFunctions";
import { useEffect, useState } from "react";

const FancyPaper = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authInfo } = useApp();
  const [remainingDays, setRemainingDays] = useState(5);

  useEffect(() => {
    const fechaVencimiento = new Date(
      authInfo?.expiration_date?.substring(0, 10)
    );
    const fechaActual = new Date();

    // Calcula la diferencia en milisegundos
    const diferenciaMilisegundos =
      fechaVencimiento.getTime() - fechaActual.getTime();

    // Calcula la diferencia en días
    const diferenciaDias = Math.ceil(
      diferenciaMilisegundos / (1000 * 60 * 60 * 24)
    );

    //console.log(diferenciaDias);

    setRemainingDays(diferenciaDias);
  }, []);

  return (
    <Box
      sx={{
        p: {
          xs: 5,
          md: `${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(6)}`,
        },
      }}
    >
      <Typography
        variant="caption"
        color="info.dark"
        onClick={() => navigate("/dashboard/post")}
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }}
      >
        {t("home")}{" "}
      </Typography>
      {props.prevpage && props.linkprevpage && (
        <Typography
          variant="caption"
          color="info.dark"
          onClick={() => navigate(props.linkprevpage)}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          {"/"} {props.prevpage}
        </Typography>
      )}
      <Typography variant="caption" color="text.primary">
        {"/ "} {props.pagetitle}
      </Typography>

      <Divider />
      <Paper
        sx={{
          pt: 5,
        }}
        {...props}
      >
        {authInfo?.expiredPlan === 1 && (
          <Box>
            <Typography variant="h3" color="error" align="center">
              {t("expiredPlan")}
            </Typography>
            {authInfo?.expiration_date && (
              <Typography variant="h3" color="error" align="center">
                {t("yourMembershipExpiredOn")}{" "}
                {formatDate(
                  new Date(authInfo?.expiration_date?.substring(0, 10))
                )}
              </Typography>
            )}
          </Box>
        )}

        {remainingDays <= 3 && authInfo.expiration_date && (
          <Box>
            {authInfo.expiration_date && (
              <Typography variant="h3" color="error" align="center">
                {t("rememberToRenewYourMembershipBefore")}{" "}
                {formatDate(
                  new Date(authInfo.expiration_date.substring(0, 10))
                )}{" "}
                {t("toContinueEnjoyingTheServices")}
              </Typography>
            )}
          </Box>
        )}
        {props.children}
      </Paper>
    </Box>
  );
};

export default FancyPaper;
