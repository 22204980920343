import { Link, useParams, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  processPaymentType,
  tokenType,
} from "../../types/ProcessPaymentTilopay";
import { useTranslation } from "react-i18next";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
  styled,
  debounce,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Divider,
  FormHelperText,
} from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import { data } from "../../resources/data/data";
import {
  loginTilopay,
  processPaymentTilopay,
} from "../../services/tilopay/TilopayService";
import { useApp } from "../../hooks/useApp";
import { useEffect } from "react";
import { setAuthData, setItem, getItem } from "../../utils/LocalStorageManager";

/* curl --location 'https://app.tilopay.com/api/v1/processPayment' \
--header 'Authorization: bearer [bearer token from GetToken method]' \
--header 'Accept: application/json' \
--header 'Content-Type: application/json' \
--data-raw '{
    "redirect" : "https://www.urlToRedirect.com",
    "key": api_key,
    "amount": "1.00",
    "currency": "USD",
    "billToFirstName": "DEMO",
    "billToLastName": "DEMO",
    "billToAddress": "San Jose",
    "billToAddress2": "Catedral",
    "billToCity": "JS",
    "billToState": "SJ",
    "billToZipPostCode": "10061",
    "billToCountry": "CR",
    "billToTelephone": "88888888",
    "billToEmail": "myemail@exapmle.com",
    "shipToFirstName":"DEMO",
    "shipToLastName":"DEMO",
    "shipToAddress":"San Jose",
    "shipToAddress2":"Catedral",
    "shipToCity":"JS",
    "shipToState":"SJ",
    "shipToZipPostCode":"10061",
    "shipToCountry":"CR",
    "shipToTelephone":"88888888",
    "orderNumber": "1212122",
	"capture": "1",
	"subscription": "0",
    "platform": "api",
    "returnData" : "dXNlcl9pZD0xMg==",
    "hashVersion" : "V2"
}' */

const PaymentFormTilopay = () => {
  const { t } = useTranslation();
  const { planID } = useParams();
  const navigate = useNavigate();
  const api_key = "6038-7043-7246-8325-7793";

  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    isAdmin,
    handleDataCompanies,
    company,
    planCompanyData,
    setPlanCompanyData,
    companyInfo,
  } = useApp();

  const plans = [
    {
      id: "1",
      name: "Básico",
      price: "45",
      description: "$450 anual gratis 2 meses",
      features: [
        `50 ${t("reservationsPerMonth")}`,
        `${t("customizableBookingPage")}`,
        `${t("managementAndControlOfReservations")}`,
        `${t("parameterizableSystem")}`,
        `${t("unlimitedUsersAndAssociates")}`,
        `${t("accessByProfiles")}`,
        `${t("configurationOfCustomizable")}`,
        `${t("onlineSupport")}`,
        `${t("multilanguageSystem")}`,
      ],
      popular: false,
    },
    {
      id: "2",
      name: "Medio",
      price: "80",
      description: "$800 anual gratis 2 meses",
      features: [
        `100 ${t("reservationsPerMonth")}`,
        `${t("customizableBookingPage")}`,
        `${t("managementAndControlOfReservations")}`,
        `${t("parameterizableSystem")}`,
        `${t("unlimitedUsersAndAssociates")}`,
        `${t("accessByProfiles")}`,
        `${t("configurationOfCustomizable")}`,
        `${t("onlineSupport")}`,
        `${t("multilanguageSystem")}`,
      ],
      popular: false,
    },
    {
      id: "3",
      name: "Plus",
      price: "180",
      description: "$1800 anual gratis 2 meses",
      features: [
        `${t("UnlimitedReservationsPerMonth")}`,
        `${t("customizableBookingPage")}`,
        `${t("managementAndControlOfReservations")}`,
        `${t("parameterizableSystem")}`,
        `${t("unlimitedUsersAndAssociates")}`,
        `${t("accessByProfiles")}`,
        `${t("configurationOfCustomizable")}`,
        `${t("onlineSupport")}`,
        `${t("multilanguageSystem")}`,
      ],
      popular: true,
    },
    {
      id: "4",
      name: "Residencial Personalizado",
      price: "99.99",
      description: "All you need for larger organizations.",
      features: [
        `${t("UnlimitedReservationsPerMonth")}`,
        `${t("customizableBookingPage")}`,
        `${t("managementAndControlOfReservations")}`,
        `${t("parameterizableSystem")}`,
        `${t("unlimitedUsersAndAssociates")}`,
        `${t("accessByProfiles")}`,
        `${t("configurationOfCustomizable")}`,
        `${t("onlineSupport")}`,
        `${t("multilanguageSystem")}`,
        `${t("messageManagement")}`,
        `${t("guestManagement")}`,
        `${t("houseOrAppartmentManagement")}`,
        `${t("financeManagement")}`,
      ],
      popular: false,
    },
  ];

  const validation = Yup.object().shape({
    redirect: Yup.string(),
    key: Yup.string(),
    amount: Yup.string().required(t("fieldRequired")),
    currency: Yup.string().required(t("fieldRequired")),
    billToFirstName: Yup.string().required(t("fieldRequired")),
    billToLastName: Yup.string().required(t("fieldRequired")),
    billToAddress: Yup.string().required(t("fieldRequired")),
    billToAddress2: Yup.string().required(t("fieldRequired")),
    billToCity: Yup.string().required(t("fieldRequired")),
    billToState: Yup.string().required(t("fieldRequired")),
    billToZipPostCode: Yup.string().required(t("fieldRequired")),
    billToCountry: Yup.string().required(t("fieldRequired")),
    billToTelephone: Yup.string().required(t("fieldRequired")),
    billToEmail: Yup.string().required(t("fieldRequired")),
    shipToFirstName: Yup.string(),
    shipToLastName: Yup.string(),
    shipToAddress: Yup.string(),
    shipToAddress2: Yup.string(),
    shipToCity: Yup.string(),
    shipToState: Yup.string(),
    shipToZipPostCode: Yup.string(),
    shipToCountry: Yup.string(),
    shipToTelephone: Yup.string(),
    orderNumber: Yup.string().required(t("fieldRequired")),
    capture: Yup.string(),
    subscription: Yup.string(),
    platform: Yup.string(),
    returnData: Yup.string(),
    hashVersion: Yup.string(),
  });

  const defaultValues = {
    redirect: "https://app.spacemanager.io/dashboard/approvePayment",
    key: api_key,
    amount: plans.find((i: any) => i.id === planID)?.price,
    //amount: "1",
    currency: "",
    billToFirstName: "",
    billToLastName: "",
    billToAddress: "",
    billToAddress2: "",
    billToCity: "JS",
    billToState: "SJ",
    billToZipPostCode: "",
    billToCountry: "CR",
    billToTelephone: "",
    billToEmail: "",
    shipToFirstName: "",
    shipToLastName: "",
    shipToAddress: "",
    shipToAddress2: "",
    shipToCity: "",
    shipToState: "",
    shipToZipPostCode: "",
    shipToCountry: "",
    shipToTelephone: "",
    orderNumber: "",
    capture: "0",
    subscription: "0",
    platform: "api",
    returnData: btoa("dXNlcl9pZD0xMg=="),
    hashVersion: "V2",
  };

  useEffect(() => {
    setPlanCompanyData({
      olderPlan: companyInfo?.planCompanyDTO?.planList,
      newPlan: {
        id: planID ?? "",
        startDate: new Date(),
      },
    });
    const savePlanCompanyData = async () => {
      await setItem("PlanCompanyData", {
        olderPlan: companyInfo?.planCompanyDTO?.planList,
        newPlan: {
          id: planID ?? "",
          startDate: new Date(),
        },
      });
    };

    savePlanCompanyData();
  }, []);

  console.log("planCompanyData: ", planCompanyData);

  // Inicializar useForm con validación y valores predeterminados
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<processPaymentType>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const onSubmit = async (data: any) => {
    console.log("onSubmit: ", data);
  };

  const handlePaymentTilopay = async (data: processPaymentType) => {
    try {
      const getToken: tokenType = await loginTilopay();
      console.log("getToken: ", getToken);
      if (getToken) {
        const payment = await processPaymentTilopay(
          data,
          getToken.access_token
        );
        if (payment) {
          window.location.href = `${payment.url}`;
          //navigate(`${payment.url}`, { replace: true });
        }
        console.log("payment: ", payment);
      }
    } catch (error: any) {
      console.log(error);
      setErrorMsg && setErrorMsg(error);
    }
  };

  return (
    <>
      <FancyPaper
        pagetitle={`${t("checkout")}`}
        prevpage={`${t("choosePlan")}`}
        linkprevpage={`/dashboard/upgradeMembershipBooking`}
      >
        <form>
          <Grid
            container
            spacing={3}
            sx={{
              pt: 4,
              pb: 8,
              px: 4,
              my: 4,
              mx: "auto",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
            }}
            maxWidth={"lg"}
          >
            <Grid item xs={12} md={2} sm={4}>
              <Controller
                name={"orderNumber"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("orderNumber")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("orderNumber")}
                    error={errors.orderNumber && Boolean(errors.orderNumber)}
                    helperText={
                      errors.orderNumber && errors.orderNumber.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={7}></Grid>
            <Grid item xs={12} md={1} sm={6}>
              <Controller
                name={"amount"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("amount")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("amount")}
                    error={errors.amount && Boolean(errors.amount)}
                    helperText={errors.amount && errors.amount.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={6}>
              <Controller
                name={"currency"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>{t("currency")}</InputLabel>
                    <Select
                      label={t("currency")}
                      variant="standard"
                      value={value}
                      {...register("currency")}
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="CRC">CRC</MenuItem>
                    </Select>
                    {errors?.currency?.message && (
                      <FormHelperText error>
                        {errors?.currency?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ pt: 4, mb: 4 }} />
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Controller
                name={"billToFirstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("firstName")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("billToFirstName")}
                    error={
                      errors.billToFirstName && Boolean(errors.billToFirstName)
                    }
                    helperText={
                      errors.billToFirstName && errors.billToFirstName.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <Controller
                name={"billToLastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("lastName")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("billToLastName")}
                    error={
                      errors.billToLastName && Boolean(errors.billToLastName)
                    }
                    helperText={
                      errors.billToLastName && errors.billToLastName.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <Controller
                name={"billToTelephone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("billToTelephone")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("billToTelephone")}
                    error={
                      errors.billToTelephone && Boolean(errors.billToTelephone)
                    }
                    helperText={
                      errors.billToTelephone && errors.billToTelephone.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <Controller
                name={"billToEmail"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("billToEmail")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("billToEmail")}
                    error={errors.billToEmail && Boolean(errors.billToEmail)}
                    helperText={
                      errors.billToEmail && errors.billToEmail.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Controller
                name={"billToCity"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("billToCity")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("billToCity")}
                    error={errors.billToCity && Boolean(errors.billToCity)}
                    helperText={errors.billToCity && errors.billToCity.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <Controller
                name={"billToZipPostCode"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("billToZipPostCode")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("billToZipPostCode")}
                    error={
                      errors.billToZipPostCode &&
                      Boolean(errors.billToZipPostCode)
                    }
                    helperText={
                      errors.billToZipPostCode &&
                      errors.billToZipPostCode.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"billToAddress"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("billToAddress")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("billToAddress")}
                    error={
                      errors.billToAddress && Boolean(errors.billToAddress)
                    }
                    helperText={
                      errors.billToAddress && errors.billToAddress.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"billToAddress2"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("billToAddress2")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("billToAddress2")}
                    error={
                      errors.billToAddress2 && Boolean(errors.billToAddress2)
                    }
                    helperText={
                      errors.billToAddress2 && errors.billToAddress2.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  mr: 2,
                }}
                //onClick={handleSubmit(onSubmit)}
                onClick={handleSubmit(handlePaymentTilopay)}
              >
                {t("purchase")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mt: 2,
                  mr: 2,
                }}
                //onClick={cancelModal}
                autoFocus
              >
                {t("cancel")}
              </Button>{" "}
            </Grid>
          </Grid>
        </form>
      </FancyPaper>
    </>
  );
};

export default PaymentFormTilopay;
