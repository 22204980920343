import { FC, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
  styled,
  debounce,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Divider,
  Typography,
} from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import { useTranslation } from "react-i18next";
import { useApp } from "../../hooks/useApp";
import { updateCompanyWithPlanID } from "../../services/company/CompanyService";
import { setAuthData, setItem, getItem } from "../../utils/LocalStorageManager";

const ApprovePayment = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    isAdmin,
    handleDataCompanies,
    company,
    planCompanyData,
    setPlanCompanyData,
    companyInfo,
  } = useApp();

  /*   useEffect(() => {
    console.log("paymentResults: ", search);
    // Obtener la URL actual
    //const url = new URL(search);
    const url = new URL(window.location.href);

    // Obtener el valor del parámetro 'code'
    const codeParam = url.searchParams.get("code");
    console.log("codeParam: ", codeParam);
  }, [search]); */

  const url = new URL(window.location.href);

  // Obtener el valor del parámetro 'code'
  const codeParam = url.searchParams.get("code");

  const handleUpdateCompany = async () => {
    setLoading && setLoading(true);
    try {
      const planCompanyData: any = await getItem("PlanCompanyData");
      //console.log(planCompanyData);
      const formData = {
        ...companyInfo,
        planList: planCompanyData?.newPlan?.id,
      };
      //console.log(formData);
      const update = await updateCompanyWithPlanID(
        companyInfo?.id,
        companyInfo?.planCompanyDTO?.id,
        companyInfo?.planDetailDTO?.id,
        companyInfo?.planCardDTO?.id,
        formData
      );

      console.log("update company plan: ", update);
      setLoading && setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading && setLoading(false);
    }
  };
  let count = 0;
  useEffect(() => {
    if (companyInfo !== null && count === 0 && codeParam === "1") {
      count++;
      handleUpdateCompany();
    }
  }, [companyInfo]);

  return (
    <>
      <FancyPaper
        pagetitle={`${t("processPayment")}`}
        prevpage={`${t("choosePlan")}`}
        linkprevpage={`/dashboard/upgradeMembershipBooking`}
      >
        <Grid
          container
          spacing={3}
          sx={{
            pt: 4,
            pb: 8,
            px: 4,
            my: 4,
            mx: "auto",
            borderRadius: "4px",
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
          }}
          maxWidth={"lg"}
        >
          <Grid item xs={12}>
            {codeParam === "1" ? (
              <Typography variant="h3" color="initial" align="center">
                Pago fue aprobado exitosamente
              </Typography>
            ) : (
              <>
                <Typography variant="h3" color="error" align="center">
                  Pago fue rechazado <br /> Por favor intente realizarlo
                  nuevamente con otra tarjeta
                </Typography>
                <Button>Seleccionar plan</Button>
              </>
            )}
          </Grid>
        </Grid>
      </FancyPaper>
    </>
  );
};

export default ApprovePayment;
