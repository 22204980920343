import { BookingPaymetReportRespose, BookingResponse, InputBookingApproval, InputBookingSearch, ResponseApproval } from "../../types/ServiceTypes";
import UrlBase from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";
import { BookingAreaResponse } from "./BookingTypes";


export const getRequestBooking = async (page: any, filter: any, company_id: any) => {
  let responseData: BookingAreaResponse = {} as BookingAreaResponse
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(
      URL.REQUEST_BOOKING_LIST(
        page,
        UrlBase.registration_x_page, company_id
      ),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_BOOKING(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any, company_id: any) => {
  let responseData: any = {};

  const requestBody = {
    name: data.name,
    description: data.description,
    status: data.status,
    capacity: data.capacity !== '' && parseInt(data.capacity) !== 0 ? parseInt(data.capacity) : null,
    bookingTime: data.bookingTime,
    userCreated: data.userCreated,
    type: data.type,
  };

  await privateFetch
    .post(URL.REQUEST_BOOKING_CREATE(company_id), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });

  return responseData;
};

export const updateRequest = async (data: any, company_id: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    name: data.name,
    description: data.description,
    capacity: data.capacity !== '' && parseInt(data.capacity) !== 0 ? parseInt(data.capacity) : null,
    bookingTime: data.bookingTime,
    status: data.status,
    type: data.type,
  };
  await privateFetch
    .put(URL.REQUEST_BOOKING_UPDATE(data.id, company_id), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_booking_update_error);
    });
  return responseData;
};

export const updateInfoConfRequest = async (data: any, company_id: any) => {
  let responseData = { code: "", message: "" };
  const requestBody = {
    id: data.id,
    description: data.description,
    status: data.status,
    startTime: data.weekdaysStartTime,
    endTime: data.weekdaysEndTime,
    startTimeWeekend: data.weekendsStartTime,
    endTimeWeekend: data.weekendsEndTime,
    publicAmount: parseInt(data.publicPrice, 10),
    associatedAmount: parseInt(data.colegiadoPrice, 10),
    details: data.areaDetails,
    idArea: data.idArea,
    availableDays: 7,
    generalDayAmount: 1500,
    associatedDayAmount: 1000,
    capacity: data.capacity,
    hasCapacity: data.hasCapacity,
    approval: data.approval,
    reservationType: data.reservationType,
    calculatePrice: data.calculatePrice,
    userModified: data.userModified
  };
  await privateFetch
    .put(URL.REQUEST_AREA_UPDATE(data.id, company_id), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_area_update_error);
    });
  return responseData;
};

export const deleteRequest = async (company_id: any, id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_BOOKING_DELETE(company_id, id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_booking_delete_error
      );
    });
  return responseData;
};

export const getBookingByWeek = async (company_id: any, data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_BOOKING_BY_WEEK(company_id,), data)
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_booking_delete_error
      );
    });
  return responseData;
}

export const createBooking = async (company_id: any, data: any,) => {
  try {
    const response = await privateFetch.post(URL.REQUEST_CREATE_BOOKING(company_id), data);
    return response.data;
  }
  catch (error) {
    throw new Error("Error al crear la reserva");
  }
}

export const bookingApproval = async (company_id: any, data: InputBookingApproval[]): Promise<ResponseApproval> => {
  try {
    const response = await privateFetch.post(URL.REQUEST_BOOKING_APPROVAL(company_id), data);

    if (response.data.result) {
      return response.data;
    } else {
      const errorMessage = response.data.message;
      throw new Error(errorMessage);
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};



export const bookingFindByStatus = async (
  company_id: any,
  data: InputBookingSearch,
  page: number,
  size?: number
): Promise<BookingResponse> => {
  try {
    const response = await privateFetch.get(URL.REQUEST_BOOKING_FIND_BY_STATUS(company_id, page, size ? size : 10, data.status, data.area, data.filter,));
    console.log(response);

    return response.data;
  }
  catch (error) {
    throw new Error("Error al crear la reserva");
  }
}


export const findDocuments = async (companyId: string, idArea?: string, areaDetailId?: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.DOCUMENTS_AREA_GET(companyId, idArea, areaDetailId), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
    });
  return responseData;
};

// export const getUrlImage = async (id:string) => {
//   let responseData = {};
//   await privateFetch
//     .get(URL.DOCUMENTS_AREA_IMG_URL(id), {
//       headers: {
//         ...CONSTANT.HEADER_TYPE,
//       },
//     })
//     .then(async (response) => {
//       responseData = await response.data;
//     })
//     .catch(async (error) => {
//     });
//   return responseData;
// };

export const getUrlImage = async (id: string) => {
  try {
    let responseData = {};

    // Reemplaza esta parte con tu lógica actual de fetch
    await privateFetch
      .get(URL.DOCUMENTS_AREA_IMG_URL(id), {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      })
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        throw new Error('Failed to fetch');
      });

    // Codificar la URL si contiene espacios
    let imageUrl = responseData.toString().replace(/ /g, '%20');

    return imageUrl; // Devolver la URL de la imagen
  } catch (error) {
    console.error(error);
    throw error; // Relanzar el error para que pueda ser manejado por el llamador de la función
  }
};

export const getUrlVideo = async (id: string) => {
  try {
    let responseData = {};

    // Reemplaza esta parte con tu lógica actual de fetch
    await privateFetch
      .get(URL.DOCUMENTS_AREA_VIDEO_URL(id), {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      })
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        throw new Error('Failed to fetch');
      });

    // Codificar la URL si contiene espacios
    let videoUrl = responseData.toString().replace(/ /g, '%20');

    return videoUrl;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadDocuments = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.DOCUMENTS_AREA_UPLOAD, data, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.message);
    });
  return responseData;
};

export const deleteDocuments = async (id: string, user: string) => {
  let responseData = {};
  await privateFetch
    .delete(URL.DOCUMENT_AREA_DELETE(id, user))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response);
    });
  return responseData;
};


/////////////////////REPORT////////////////////
/**
 * 
 * @param company_id id de la compañía
 * @param status_id id del estado
 * @param startDate fecha inicio del reporte formato -> yyyy-mm-dd
 * @param endDate fecha final del reporte formato -> yyyy-mm-dd
 */
export const reportBookingPayment = async (
  company_id: number,
  status_id: number,
  startDate: string,
  endDate: string
): Promise<BookingPaymetReportRespose> => {
  try {
    const response = await privateFetch.get(URL.REQUEST_REPORT_BOOKING_PAYMENT_FIND_BY_COMPANY_STATUS_DATES(company_id, status_id, startDate, endDate));
    console.log(response);

    return response.data;
  }
  catch (error) {
    throw new Error("Error al obtener reporte de la reserva");
  }
}

/**
 * 
 * @param company_id id de la compañía
 * @param status_id id del estado
 * @param startDate fecha inicio del reporte formato -> yyyy-mm-dd
 * @param endDate fecha final del reporte formato -> yyyy-mm-dd
 * @param extension tipo de archivo a descargar pdf o xlsx
 */
export const reportBookingPaymentDownload = (
  company_id: number,
  status_id: number,
  startDate: string,
  endDate: string,
  extension: string
): void => {
  try {
    window.open(UrlBase.base_url + UrlBase.api_context + URL.REQUEST_REPORT_DOWNLOAD_BOOKING_PAYMENT_FIND_BY_COMPANY_STATUS_DATES(company_id, status_id, startDate, endDate, extension), 'blank');
  }
  catch (error) {
    throw new Error("Error al obtener reporte de la reserva");
  }
}

/**
 * 
 * @param company_id id de la compañía
 * @param date fecha inicio del reporte formato -> yyyy-mm-dd
 * @param area_id id del area(Instalación)
 * @param area_detail_id id del areaDetail(SubInstalación) -> no requerido
 */
export const reportBookingCapacity = async (
  company_id: number,
  date: string,
  area_id: number,
  area_detail_id?: number
): Promise<BookingPaymetReportRespose> => {
  try {
    const response = await privateFetch.get(URL.REQUEST_REPORT_BOOKING_CAPACITY_FIND_BY_COMPANY_DATES(company_id, date, area_id, area_detail_id));
    console.log(response);

    return response.data;
  }
  catch (error) {
    throw new Error("Error al obtener reporte de capacidad");
  }
}

/**
 * 
 * @param company_id id de la compañía
 * @param date fecha inicio del reporte formato -> yyyy-mm-dd
 * @param area_id id del area(Instalación)
 * @param area_detail_id id del areaDetail(SubInstalación) -> no requerido
 * @param extension tipo de archivo a descargar pdf o xlsx
 */
export const reportBookingCapacityDownload = (
  company_id: number,
  date: string,
  area_id: number,
  extension: string,
  area_detail_id?: number
): void => {
  try {
    window.open(UrlBase.base_url + UrlBase.api_context + URL.REQUEST_REPORT_DOWNLOAD_BOOKING_CAPACITY_FIND_BY_COMPANY_DATES(company_id, date, area_id, extension, area_detail_id), 'blank');
  }
  catch (error) {
    throw new Error("Error al obtener reporte de capacidad");
  }
}

